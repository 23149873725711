import axios from 'axios';
import {userStore} from '../stores/UserStore';

let baseUrl = 'http://localhost:4002/v1';
if (process.env.VUE_APP_ENV === 'DEV') {
   baseUrl = 'http://localhost:4002/v1';
} else if (process.env.VUE_APP_ENV === 'STAGE') {
   baseUrl = 'https://stage-api.ivp.ai/v1';
} else if (process.env.VUE_APP_ENV === 'PROD') {
   baseUrl = 'https://api.ivp.ai/v1';
}

const apiClient = axios.create({
baseURL: baseUrl,
   withCredential: false,
});

apiClient.interceptors.request.use(
   async (config) => {
      const user = userStore();

      config.headers = {
         Authorization: user.getToken,
      };
      return config;
   },
   (error) => {
      Promise.reject(error);
   }
);


export default {

   getAcyclicDatasets(workspaceId, schemaTo, datasetName) {
		return apiClient.post(`/pipelines/get/acyclic`, {
      workspaceId,
      schemaTo,
      datasetName
    });
	},

   deletePipeline(pipelineId) {
		return apiClient.post('/pipelines/delete', {
			pipelineId,
		});
	},

	getPipelines(workspaceId) {
		return apiClient.get(`/pipelines/get/${workspaceId}`);
	},

	getPipeline(pipelineId) {
		return apiClient.get(`/pipelines/get/single/${pipelineId}`);
	},

   createPipeline(orgId, workspaceId, pipelineName, pipeline) {
		return apiClient.post('/pipelines/create', {
      orgId,
      workspaceId,
      pipelineName,
      pipeline
		});
	},

	savePipeline(pipeline, runType, workspaceId, schemaId, schema, pipelineId) {
		return apiClient.post('/pipelines/save', {
			pipeline,
			runType,
			workspaceId,
			schemaId,
			schema,
			pipelineId,
		});
	},

	runNode(step, workspaceId, schema, pipelineId) {
		return apiClient.post('/pipelines/node/run', {
			step,
			workspaceId,
			schema,
			pipelineId,
		});
	},

	updatePipeline(newPipeline, oldPipeline, workspaceId, schema, pipelineId) {
		return apiClient.post('/pipelines/update', {
			newPipeline,
			oldPipeline,
			workspaceId,
			schema,
			pipelineId,
		});
	},

	analyseUpdate(newPipeline, oldPipeline, workspaceId, schema, pipelineId, runType) {
		return apiClient.post('/pipelines/update/downstream', {
			newPipeline,
			oldPipeline,
			workspaceId,
			schema,
			pipelineId,
			runType,
		});
	},

   nodePreview(workspaceId, tableToDatabaseName) {
		return apiClient.post(`/pipelines/node/preview`, {
			workspaceId,
			tableToDatabaseName,
		});
	},

   // this.nodes, this.originalNodes, this.user.getCurrentWorkspace, this.schema, this.$route.query.pipelineId, 'save'
   updateDownstream(newPipeline, oldPipeline, workspaceId, schema, pipelineId, runType) {
      return apiClient.post(`/pipelines/update/downstream`, {
         newPipeline,
         oldPipeline,
         workspaceId,
         schema,
         pipelineId,
         runType
      });
   },

   calculateDelete(pipelineToDelete, workspaceId){
		return apiClient.post(`/pipelines/calc/delete`, {
			pipelineToDelete,
			workspaceId
		})
   },

   executeDelete(body){
		return apiClient.post(`/pipelines/execute/delete`, body)
	},
   
	validateCustomSql(sql) {
		return apiClient.post(`/pipelines/validate/sql`, { sql });
	}

}