import axios from 'axios';
import {userStore} from '../stores/UserStore';
let baseUrl = 'http://localhost:4002/v1';

if (process.env.VUE_APP_ENV === 'DEV') {
	baseUrl = 'http://localhost:4002/v1';
} else if (process.env.VUE_APP_ENV === 'STAGE') {
	baseUrl = 'https://stage-api.ivp.ai/v1';
} else if (process.env.VUE_APP_ENV === 'PROD') {
	baseUrl = 'https://api.ivp.ai/v1';
}

const apiClient = axios.create({
	baseURL: baseUrl,
	withCredential: false,
});

apiClient.interceptors.request.use(
	async (config) => {
		const user = userStore();

		config.headers = {
			Authorization: user.getToken,
		};
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

export default {

   // Profile

   updateProfile(userId, firstName, lastName, email, password) {
      return apiClient.post('/settings/profile/update', {
         userId,
         firstName,
         lastName,
         email,
         password
      })
   },

   updatePassword(userId, newPassword1, newPassword2, oldPassword) {
      return apiClient.post(`/settings/password/update`, {
         userId,
         newPassword1,
         newPassword2,
         oldPassword
      });
   },


   // Security 

   // TODO - make endpoints


   // Notifications

   // TODO - make endpoints for what is in the settings/notifcations backend router


   // Users

   checkUserExists(email) {
      return apiClient.get(`/settings/users/checkExists/${email}`);
   },

   getOrganisationUsers(orgId) {
      return apiClient.get(`/settings/users/${orgId}`);
   },

   addUser(orgId, email) {
      return apiClient.post(`/settings/users/add`, {
         orgId,
         email,
      });
   },

   deleteUser(userId, editUserDetails, password) {
      return apiClient.post('/settings/users/delete', {
         userId,
         editUserDetails,
         password
      });
   },

   checkPassword(userId, password) {
      return apiClient.post('/settings/checkPassword', {
         userId,
         password
      });
   },

   sendReminderInvite(orgId, email) {
      return apiClient.post(`/settings/users/reminderInvite`, {
         orgId,
         email,
      });
   },

   editUserDetails(userId, editUserDetails, password) {
      return apiClient.post(`/settings/users/edit`, {
         userId,
         editUserDetails,
         password
      });
   },


   // Workspaces

	addWorkspace(orgId, userId, name) {
		return apiClient.post('/settings/workspaces/add', {
			orgId,
			userId,
			name,
		});
	},

	getWorkspacesForOrg(orgId) {
		return apiClient.get('/settings/workspaces/get/org/' + orgId);
	},

	getForUser(userId) {
		return apiClient.get('/settings/workspaces/get/user/' + userId);
	},

   getWorkspacesAccess(workspaceId, orgId) {
		return apiClient.get('/settings/workspaces/access/list/' + workspaceId + '/' + orgId
		);
	},

	shareWorkspace(workspaceId, userId) {
		return apiClient.post('/settings/workspaces/access/add/' + workspaceId + '/' + userId
		);
	},

	unshareWorkspace(workspaceId, userId) {
		return apiClient.post('/settings/workspaces/access/remove/' + workspaceId + '/' + userId
		);
   },   

   updateWorkspace(workspace, newWorkspaceName) {
      return apiClient.post(`/settings/workspaces/update`, {
         workspace,
         newWorkspaceName
      });
   },

   validateDeleteWorkspace(workspaceId) {
      return apiClient.get(`/settings/workspaces/validateDelete/${workspaceId}`);
   },

   deleteWorkspace(workspace) {
      return apiClient.post(`/settings/workspaces/delete`, {
         workspace
      });
   }

}