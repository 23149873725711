<template>
<v-container>
    <form>

        <v-row>
            <v-col cols="6" class="pt-0">
                <v-text-field data-cy="transformationCalculateTableTo" color="primary" :hint="errors.tableTo?.join(' - ')" outlined dense v-model="data.step.tableTo" label="Step Name" :error="(errors?.tableTo)?true:false" @input="onChange(data.step, 'tableTo')" persistent-hint></v-text-field>
            </v-col>
            <v-col cols="6" class="pt-1">
                <v-chip>BETA Feature</v-chip>
            </v-col>

            <v-col cols="12" class="py-01">
                <v-tabs  v-model="tab" class="mt-n8">
                   
                    <v-tab>Column Mapping</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" class="pt-4">
                    <v-tab-item>
                        <v-row v-for="(item, index) in data.step.tableToColumns" :key="index" class="mb-n7">
                            <v-col cols="6"><v-text-field label="column_name" outlined dense readonly v-model="data.step.tableToColumns[index].column_name" disabled></v-text-field></v-col>
                            <v-col cols="6"><v-select label="Data Type" outlined dense v-model="data.step.tableToColumns[index].data_type_id" :items="dataTypes" item-text="label" item-value="value"></v-select></v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>

            </v-col>


            <v-col cols="12">
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :data-cy="`saveButton${node.demoNodeIndex}`" @click="emitDataAction()" color="primary"> {{data.node.data.status === 'unconfigured' ? 'Save' : 'Update'}} </v-btn>
                </v-card-actions>
            </v-col>
            <!--
            <v-col cols="10" class="pt-3 pb-0">
                <v-select item-color="primary" color="primary" dense small-chips outlined label="Select a Column to Include" :items="data.step.tableFromNumericalColumns" item-text="pretty_name" item-value="column_name" v-model="data.step.columnToAdd" :error="(errors?.columnToAdd)?true:false" @input="onChange(data.step, 'columnToAdd')" :hint="errors.columnToAdd?.join(' - ')" persistent-hint></v-select>
            </v-col>
            <v-col cols="2" class="pt-3 pb-0">
                <v-btn color="primary" @click="addToFormula(' ' + data.step.columnToAdd)" block>
                    <v-icon left>fa-thin fa-plus</v-icon>Add
                </v-btn>
            </v-col>
            <v-col cols="12" class="py-0">
                <v-card-actions class="py-0 pr-1">
                    <v-spacer></v-spacer>
                    <v-btn v-if="data.node.data.status === 'unconfigured'" @click="emitDataAction" :disabled="disabledRules" color="primary">Save</v-btn>
                    <v-btn v-if="data.node.data.status !== 'unconfigured'" @click="emitDataAction" :disabled="disabledRules" color="primary">Update</v-btn>
                </v-card-actions>
            </v-col>
            -->
        </v-row>
    </form>
</v-container>
</template>

<script>
import {
    emitData
} from '@/utils/eventBus'
import formRules from '@/utils/validation/formRules'
import nodeCode from '@/utils/pipelines/nodeCode'
import pipelinesApi from '@/services/pipelines';
import {
   userStore
} from '@/stores/UserStore';

export default {
    setup() {
      const user = userStore()
      return {
         user,
      }
   },
    name: 'CalculateNode',

    mixins: [formRules, nodeCode],

    props: {
        node: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    data() {
        return {
            errors: {},
            tab: 'editor',
            previewHeaders: [],
            previewData: [],
            dataTypes: [
                {value: 1, label: 'Number'},
                {value: 2, label: 'Text'},
                {value: 3, label: 'Timestamp'},
                {value: 4, label: 'Boolean'},
                {value: 5, label: 'Geo Lat/Lng'},
            ],
            data: {
                step: {
                    frontendFormula: '',
                },
                rules: {
                    tableTo: {
                        rule: 'notEmpty'
                    },
                    newColumnName: {
                        rule: 'notEmpty'
                    },
                    frontendFormula: {
                        rule: 'notEmpty'
                    },
                    tableFromColumns: {
                        rule: 'notEmpty'
                    }
                }
            }
        }
    },

    created() {

        this.processParentData()
        this.data.step.type = 'changedatatype'
        this.data.step.prefix = 'WITH source AS (\n SELECT * FROM previous_step\n)'
        this.validateSql();
        console.log('n', this.node.data.status)
    },

    computed: {

        disabledRules() {
            let isDisabled = false;
            for (const key in this.errors) {
                if (this.errors[key] != null) {
                    isDisabled = true
                }
            }
            if (
                !this.data.step.tableTo ||
                !this.data.step.newColumnName ||
                !this.data.step.frontendFormula ||
                !this.data.step.tableFromColumns
            ) {
                isDisabled = true
            }
            return isDisabled;
        },

    },

    methods: {
        getCast(dataTypeId) {
          if(dataTypeId === 1) {
            return '::numeric'
          } else if(dataTypeId === 2) {
            return '::varchar'
          } else if(dataTypeId === 3) {
            return '::timestamp'
          } else if(dataTypeId === 4) {
            return '::boolean'
          } else if(dataTypeId === 5) {
            return '::varchar'
          }
        },
        validateSql() {
            console.log('a', this.data.step.tableToColumns, this.data.step.tableFromColumns)
            const columns = (this.node.data.status === 'unconfigured') ? this.data.step.tableFromColumns : this.data.step.tableToColumns;
            if (this.node.data.status === 'unconfigured') { this.data.step.tableToColumns = structuredClone(this.data.step.tableFromColumns)}
            this.data.step.frontendFormula = `SELECT ${columns.map(x => x.column_name + this.getCast(x.data_type_id))} FROM source`
            const previous_step = `"${this.data.step.schemaFrom}"."${this.data.step.tableFrom1DatabaseName}"`
            const sql = `
            WITH source AS (
                SELECT * FROM ${previous_step} LIMIT 5
            )

            ${this.data.step.frontendFormula}
            `
            console.log(sql)
            pipelinesApi.validateCustomSql(sql)
                .then(response => {
                    console.log(response)
                   /* this.previewHeaders = Object.keys(response.data[0]).map(key => {
                        return {
                            text: key,
                            value: key
                        }
                    })
                    this.previewData = response.data;*/
                    this.data.step.tableToColumns = Object.keys(response.data[0]).map(key => {
                        return {
                            // find the data type id by searching for the column name in table from column,
                            data_type_id: this.data.step.tableToColumns.find(x => x.column_name === key).data_type_id,
                            column_name: key,
                            pretty_name: key
                        }
                    })

                })
                .catch(error => {
                    console.log(error)
                })
        },
        clearTextArea() {

            this.data.step.frontendFormula = ''
            this.data.step.backendFormula = ''
            this.$forceUpdate();

            this.onChange(this.data.step, 'frontendFormula')

        },


        addToFormula(frontendItem, backendItem) {

            this.data.step.frontendFormula === null ? this.data.step.frontendFormula = '' : {}
            this.data.step.backendFormula === null ? this.data.step.backendFormula = '' : {}

            this.data.step.frontendFormula = this.data.step.frontendFormula + frontendItem;
            this.data.step.backendFormula = this.data.step.backendFormula + backendItem;
            this.$forceUpdate();

            this.onChange(this.data.step, 'frontendFormula')

        },

        emitDataAction() {
            console.log(this.data.step.tableToColumns)

            this.data.step.frontendFormula = `SELECT ${this.data.step.tableToColumns.map(x => x.column_name + this.getCast(x.data_type_id))} FROM source`

            this.data.step.type = 'changedatatype'
            emitData('emit-data', this.data);
        }
    }
}
</script>
