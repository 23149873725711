<template>
<v-container ref="observeMe">
   <form>

      <v-row>
         <v-col cols="12">
            <v-text-field color="primary" outlined dense v-model="data.step.tableTo" label="Step Name" :hint="errors.tableTo?.join(' - ')" :error="(errors?.tableTo)?true:false" @input="onChange(data.step, 'tableTo')" persistent-hint></v-text-field>
         </v-col>
         
         <v-col cols="12" class="mb-n5">
            <v-chip class="mb-2">{{ (onlyNumericFieldsRemaining)? 'Valid: Only numeric fields remaining': 'Invalid: All unchecked fields must be numeric' }}</v-chip>
            <v-select  item-color="primary" color="primary" multiple dense small-chips outlined label="Select columns to keep, unchecked columns will be pivoted" :items="data.step.tableFromColumns" item-text="pretty_name" item-value="column_name" v-model="data.step.tableToColumns" return-object :hint="errors.tableToColumns?.join(' - ')" :error="(errors?.tableToColumns)?true:false" @input="onChange(data.step, 'tableToColumns')" persistent-hint></v-select>
         </v-col>

         <v-col cols="12">
            <v-card-actions>
               <v-spacer></v-spacer>
              
               <v-btn :data-cy="`saveButton${node.demoNodeIndex}`" @click="emitDataAction()" color="primary" :disabled="!onlyNumericFieldsRemaining"> {{data.node.data.status === 'unconfigured' ? 'Save' : 'Update'}} </v-btn>
            </v-card-actions>
         </v-col>
      </v-row>
   </form>
</v-container>
</template>

<script>
import {
   emitData
} from '@/utils/eventBus'
import formRules from '@/utils/validation/formRules'
import nodeCode from '@/utils/pipelines/nodeCode'

export default {
   name: 'PivotNode',
   mixins: [formRules, nodeCode],
   props: {
      node: {
         type: Object,
         default: () => {
            return {}
         }
      }
   },
   data() {
      return {
         errors: {},
         data: {
            step: {},
            rules: {
               tableTo: {
                  rule: 'notEmpty'
               },
               tableToColumns: {
                  rule: 'notEmptyArray'
               }
            }
         }
      }
   },
   created() {

      this.processParentData();
      this.data.step.type = 'pivot'
      // @focus="refreshCols()"
       
   },
   mounted() {
    this.createObserver();
   },
   beforeDestroy() {
    if (this.observer) {
      this.observer.unobserve(this.$refs.observeMe);
      this.observer.disconnect();
    }
  },
   computed: {
      disabledRules() {
         let isDisabled = false;
         for (const key in this.errors) {
            if (this.errors[key] != null) {
               isDisabled = true
            }
         }
         if (
            !this.data.step.tableTo ||
            this.data.step.tableToColumns.length < 1
         ) {
            isDisabled = true
         }
         return isDisabled;
      },
      onlyNumericFieldsRemaining() {
         // the remaining items must all have the data type of number
         const remainingItems = this.data.step.tableFromColumns.filter(x => !this.data.step.tableToColumns.map(x => x.mapping_id).includes(x.mapping_id))
         return remainingItems.every(x => x.data_type_id === 1)
        
      }
   },
   methods: {
      createObserver() {
      const options = {
        root: null, // observing changes in the viewport
        threshold: 0.1, // trigger callback when 10% of the element is visible
      };

      this.observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // Element has become visible
             console.log('Element is visible', observer);
             this.refreshCols()
             this.$forceUpdate()
          } else {
            // Element has become invisible
            console.log('Element is not visible');
          }
        });
      }, options);

      this.observer.observe(this.$refs.observeMe);
    },
      refresh() {
         this.refreshCols()
      },

      emitDataAction() {



         // remove columns that don't have the correct column name
         const validColumns = this.data.step.tableFromColumns.map(x => x.column_name)
         this.data.step.tableToColumns = this.data.step.tableToColumns.filter(x => validColumns.includes(x.column_name))
         this.data.step.columnsToKeep = structuredClone(this.data.step.tableToColumns.map(x => x.column_name)) 
         // Pivot all table from columns not in the columns to keep
         this.data.step.columnsToPivot = this.data.step.tableFromColumns.filter(x => !this.data.step.columnsToKeep.includes(x.column_name)).map(x => x.column_name)
         this.data.step.tableToColumns.push(
            {
               column_name: 'attribute',
               pretty_name: 'Attribute',
               data_type_id: 2
            },
            {
               column_name: 'value',
               pretty_name: 'Value',
               data_type_id: 1
            }
         );


         

         emitData('emit-data', this.data);
      }
   }
}
</script>
