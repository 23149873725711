<template>
<v-container>
    <form>

        <v-row>

            <v-col cols="12">
                <v-text-field @focus="refreshCols()" color="primary" :hint="errors.tableTo?.join(' - ')" outlined dense v-model="data.step.tableTo" label="Step Name" @input="onChange(data.step, 'tableTo')" :error="(errors?.tableTo)?true:false" persistent-hint></v-text-field>
            </v-col>

            <v-col cols="12">
                <v-select @focus="refreshCols()" color="primary" item-color="primary" dense small-chips outlined label="Date/Time Column" :items="data.step.tableFromColumns.filter(x => x.data_type_id === 3)" item-text="pretty_name" item-value="column_name" v-model="data.step.newColumnName" return-object @input="onChange(data.step, 'aggBy')" :error="(errors?.aggBy)?true:false" :hint="errors.aggBy?.join(' - ')" persistent-hint></v-select>
                <!--<v-select @focus="refreshCols()" color="primary" item-color="primary" dense multiple small-chips outlined label="Columns To Aggregate" :items="data.step.tableFromColumns" item-text="pretty_name" item-value="column_name" v-model="data.step.aggs" return-object @input="onChange(data.step, 'aggs')" :error="(errors?.aggs)?true:false" :hint="errors.aggs?.join(' - ')" persistent-hint></v-select>-->
                
            </v-col>
            <!-- For each column in p2 select an type sum/count/avg/max/min/distinct count with option to add additional agg for that column can't do same agg on same column twice -->
           

            <v-col cols="12">
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :data-cy="`saveButton${node.demoNodeIndex}`" @click="emitDataAction()" color="primary"> {{data.node.data.status === 'unconfigured' ? 'Save' : 'Update'}} </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
    </form>

</v-container>
</template>

<script>
import { emitData } from '@/utils/eventBus'
import formRules from '@/utils/validation/formRules'
import nodeCode from '@/utils/pipelines/nodeCode'

export default {
    name: 'DateTimeNode',
    mixins: [formRules, nodeCode],
    props: {
        node: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            errors: {},
            data: {
                step: {},
                rules: {
                    tableTo: {
                        rule: 'notEmpty'
                    },
                    aggBy: {
                        rule: 'notEmptyArray'
                    },
                    aggs: {
                        rule: 'notEmptyArray'
                    },
                },
            }
        }
    },
    created() {

        this.processParentData();
        this.data.step.type = 'datetime'

    },

    computed: {

        disabledRules() {

            let isDisabled = false;
            for (const key in this.errors) {
                if (this.errors[key] != null) {
                    isDisabled = true
                }
            }
            if (
                !this.data.step.tableTo ||
                this.data.step.aggBy.length < 1 ||
                this.data.step.aggs.length < 1 ||
                this.data.step.calcs.length != this.data.step.aggs.length
            ) {
                isDisabled = true
            }
            return isDisabled;
        },

    },

    methods: {

        addAgg(item) {

            this.data.step.newColumnName = item

        },


        async emitDataAction() {

            // filter aggBy columns that don't have a valid column name 
            const validColumns = this.data.step.tableFromColumns.map(x => x.column_name);
            console.log(validColumns)
/*
            this.data.step.aggBy = this.data.step.aggBy.filter(x => validColumns.includes(x.column_name))
          
            this.data.step.tableToColumns = []
            
            for (let a = 0; a < this.data.step.aggBy.length; a++) {
                this.data.step.tableToColumns.push(this.data.step.aggBy[a]);
            }

            for (var i = 0; i < this.data.step.aggs.length; i++) {
                const aggregatedColumnInfo = {};
                aggregatedColumnInfo.column_name = this.data.step.calcs[i].replace(/\s/g, '_').replaceAll('(', '_').replaceAll(')', '_').toLowerCase() + '_of_' + this.data.step.aggs[i].column_name
                aggregatedColumnInfo.pretty_name = this.data.step.calcs[i].replaceAll('(', ' ').replaceAll(')', ' ') + ' Of ' + this.data.step.aggs[i].pretty_name
                aggregatedColumnInfo.data_type_id = 1
                this.data.step.tableToColumns.push(aggregatedColumnInfo)
            }


*/
            this.data.step.tableToColumns = structuredClone(this.data.step.tableFromColumns)
            // Check that each column doesn't already exist in the tableToColumns before pushing 

            this.data.step.tableToColumns.push({
                column_name: 'day_of_week',
                pretty_name: 'Day of Week',
                data_type_id: 2
            })
            this.data.step.tableToColumns.push({
                column_name: 'day_of_month',
                pretty_name: 'Day of Month',
                data_type_id: 2
            })
            this.data.step.tableToColumns.push({
                column_name: 'month_of_year',
                pretty_name: 'Month of Year',
                data_type_id: 2
            })
           /* this.data.step.tableToColumns.push({
                column_name: 'hour_of_day',
                pretty_name: 'Hour of Day',
                data_type_id: 2
            })*/
            this.data.step.tableToColumns.push({
                column_name: 'year',
                pretty_name: 'Year',
                data_type_id: 2
            })
            this.data.step.tableToColumns.push({
                column_name: 'week_of_year',
                pretty_name: 'Week of Year',
                data_type_id: 2
            })

            emitData('emit-data', this.data);

        }
    }
}
</script>
