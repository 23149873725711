<template>
<v-app id="inspire">
    <v-navigation-drawer v-model="drawer" :mini-variant="miniDrawer" app color="white" light class="elevation-0" permanent clipped-left clipped flat v-if="ignorePaths">
        <v-layout d-flex flex-column justify-space-between fill-height>
            <v-list shaped>
                <template v-if="!user.viewOnly">
                    <v-list-item-group color="primary">
                        <v-list-item v-for="([icon, text, link], i) in computedItems" :key="'d' + i" :ref="`nav${text}`" class="justify-start px-0" style="pointer-events: default" :data-cy="text" @click="navTo(link)" :disabled="$route.path === link">
                            <v-list-item-icon class="mr-0 justify-center pl-4" style="width: 30px">
                                <v-icon size="25" color="primary">{{ icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title class="pl-5 text-overline">{{

text

}}</v-list-item-title>
                                <!--<v-list-item-subtitle>{{ subtitle }}</v-list-item-subtitle>-->
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </template>
                <template v-else-if="user.viewOnly">
                    <v-list-item-group color="primary">
                        <v-list-item v-for="([icon, text, link], i) in viewOnlyItems" :key="'e' + i" :ref="`nav${text}`" class="justify-start px-0" style="pointer-events: default" :data-cy="text" @click="navTo(link)" :disabled="$route.path === link">
                            <v-list-item-icon class="mr-0 justify-center pl-4" style="width: 40px">
                                <v-icon size="25" color="primary">{{ icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title class="pl-4">{{

text

}}</v-list-item-title>
                                <!--<v-list-item-subtitle>{{ subtitle }}</v-list-item-subtitle>-->
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </template>
            </v-list>
            <v-card flat class="pb-3">
                <v-row>
                    <v-col cols="11" class="mx-3 pb-5" v-show="!miniDrawer">
                        <div class="d-flex flex-column justify-center align-center">
                            <v-select :menu-props="{top: true, offsetY: true}" auto class="pb-2" hide-details dense outlined label="Workspace" v-model="workspace" :items="workspaces" color="primary" item-text="name" item-value="workspace_id" @change="changeWorkspace($event)" @focus="getWorkspaces()"></v-select>
                            <v-btn width="90%" outlined color="primary" small @click="miniDrawer = !miniDrawer">Hide Menu</v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12" class="ml-3 mr-3 mb-n2" v-show="miniDrawer">
                        <v-icon v-if="miniDrawer" @click="miniDrawer = false" class="mb-5" color="primary">fa-thin fa-chart-user</v-icon>
                    </v-col>
                </v-row>
            </v-card>
        </v-layout>
    </v-navigation-drawer>

    <v-main>
        <v-app-bar clipped-left app color="primary" dark class="elevation-1" v-if="ignorePaths">
            <v-app-bar-nav-icon v-if="user.getWhiteLabel === false">
                <v-img src="./assets/IVP-01-White.svg" height="75" contain></v-img>
            </v-app-bar-nav-icon>
            <v-app-bar-nav-icon v-else class="ml-8">
                <v-img :src="user.getWhiteLabelLogo" height="50" contain></v-img>
            </v-app-bar-nav-icon>
            <v-toolbar-title v-if="user.getWhiteLabel === false" class="ml-n3 hite--text text-h4" :style="'overflow: visible;'"><strong>IVP</strong>.ai

                <!---	<br />

<v-chip x-small color="white" class="mt-n2"

><span class="text-caption black--text"

>By Discovr Analytics</span

></v-chip

>-->
                <!--<v-breadcrumbs :items="breadcrumbs" class="pa-0 ma-0 pt-1" dark></v-breadcrumbs>-->
            </v-toolbar-title>

            <!--<v-btn fab x-small color="primary" class="mr-2" :retain-focus-on-click="false">
                <v-icon medium @click="$vuetify.theme.dark = !$vuetify.theme.dark" :retain-focus-on-click="false" color="black">mdi-theme-light-dark</v-icon>
            </v-btn>-->

            <!--<v-btn fab x-small color="primary" class="mr-2" :retain-focus-on-click="false">
                <v-icon medium @click="drawer = !drawer" :retain-focus-on-click="false">mdi-menu</v-icon>
            </v-btn>-->
            <SearchBar v-if="user.getSubscription !== 2" class="ml-n3" />
            <v-spacer v-else></v-spacer>
            <v-btn data-cy="userButton" fab x-small color="white" class="mr-2" :retain-focus-on-click="false" @click="navTo('/settings')">
                <v-icon medium :retain-focus-on-click="false" color="primary">fa-gear</v-icon>
            </v-btn>
            <v-btn v-if="user.getSubscription !== 2" data-cy="userButton" fab x-small color="white" class="mr-2" :retain-focus-on-click="false" @click="openSupport()">
                <v-icon medium :retain-focus-on-click="false" color="primary">fa-question</v-icon>
            </v-btn>
            <!--<HelpButton :details="this.$route" />-->
            <!--<v-menu offset-y max-height="70vh">

<template v-slot:activator="{on, attrs}">

<v-btn

data-cy="notificationsButton"

v-bind="attrs"

v-on="on"

fab

x-small

color="white"

class=""

:retain-focus-on-click="false"

>

<v-badge

color="error"

x-small

:content="user.notifications.length"

:value="user.notifications.length"

overlap

>

<v-icon

medium

:retain-focus-on-click="false"

color="primary"

>fa-bell</v-icon

>

</v-badge>

</v-btn>

</template>

<v-list class="pt-0" v-if="user.notifications.length > 0">

<v-card

tile

height="28px"

@click="user.notifications = []"

color="accent"

flat

class="py-0"

style="min-height: 24px"

>

<v-card-title class="text-body-1 align-center py-1">

Clear all

<v-spacer />

<v-icon small class="pr-3" color="primary"

>fa-thin fa-circle-xmark</v-icon

>

</v-card-title>

</v-card>

<v-list-item

v-for="(item, index) in user.notifications"

:key="'f' + index"

two-line

@click="goToNotification(item)"

>

<v-list-item-content>

<v-list-item-title>{{ item.title }}</v-list-item-title>

<v-list-item-subtitle>{{

item.type

}}</v-list-item-subtitle>

</v-list-item-content>

<v-list-item-icon @click="user.removeNotification(index)">

<v-icon small class="mt-3">fa-thin fa-xmark</v-icon>

</v-list-item-icon>

</v-list-item>

</v-list>

</v-menu>-->

            <v-btn v-if="user.getSubscription !== 2" data-cy="chatButton" color="white" class="ml-10 primary--text" :retain-focus-on-click="false" @click="openChat()">
                AI Assistant
                <v-icon medium :retain-focus-on-click="false" color="primary" class="ml-2">fa-microchip-ai</v-icon>
            </v-btn>
        </v-app-bar>

        <router-view class="mt-3" :key="key" />
        <SnackBar></SnackBar>
    </v-main>

    <v-dialog v-model="chatView" width="40%">
        <v-card style="position: fixed; bottom: 10px; right: 10px; top: 10px" width="40%" color="accent">
            <v-card-title><strong>IVP</strong> Assistant
                <!--<v-chip color="error" x-small class="ml-2 mt-1"

>Please note this feature is experimental, it uses AI

and therefore sometimes will make things up.</v-chip

>-->
                <v-spacer></v-spacer>
                <v-icon @click="chatView = !chatView">mdi-close</v-icon>
            </v-card-title>
            <v-card-subtitle class="text-overline">Ask questions to analyse your data</v-card-subtitle>
            <v-container fluid fill-height>
                <v-row class="fill-height">
                    <v-col cols="12" class="fill-height">
                        <v-card class="d-flex flex-column fill-height" flat color="accent" height="83vh">
                            <v-row class="my-list mb-5" id="box">
                                <v-col cols="12">
                                    <v-sheet outlined :color="

item.role === 'user'

? 'primary'

: 'secondary'

" rounded v-for="(item, i) in chat" :key="i" class="ma-2">
                                        <v-card color="accent" outlined flat>
                                            <v-card-text class="black--text text-pre-line" v-if="item.content !== 'Chart'">{{ item.content }}</v-card-text>
                                            <div v-else>
                                                <v-card-text>Chart</v-card-text>
                                                <canvas :id="item.id + '-chart-chat'" class="ma-2"></canvas>
                                            </div>
                                            <v-card-actions class="mt-n2">

                                                <div v-if="item.role !== 'user' && i !== 0">
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="success" x-small class="ml-2" @click="rank(item.id, 'bad')">
                                                        <v-icon x-small>mdi-check</v-icon>
                                                    </v-btn>
                                                    <v-btn color="error" x-small class="ml-3" @click="rank(item.id, 'bad')">
                                                        <v-icon x-small>mdi-close</v-icon>
                                                    </v-btn>
                                                </div>
                                            </v-card-actions>
                                        </v-card>
                                    </v-sheet>
                                </v-col>
                            </v-row>

                            <center v-if="isLoading">
                                <!--<v-progress-circular

indeterminate

color="primary"

></v-progress-circular>-->
                                <LogoLoader height="100" width="100" />
                                <v-card-text class="text--overline mt-n5">Generating Response...</v-card-text>
                            </center>

                            <v-spacer></v-spacer>
                            <v-card-actions class="pb-1">
                                <v-row no-gutters>
                                    <v-col>
                                        <v-card-text class="text-caption ml-n3">Please note this feature is experimental,
                                            it uses AI and therefore sometimes will make
                                            things up.</v-card-text>
                                        <v-row>
                                            <v-col cols="8">
                                                <v-select :disabled="isLoading" outlined dense label="Datasets" :items="datasets" item-value="dataset_id" item-text="display_name" multiple v-model="selectedDatasets"></v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-switch :disabled="isLoading" v-model="promptType" class="mt-n1" label="Chart"></v-switch>
                                            </v-col>
                                        </v-row>
                                        <div class="d-flex flex-row align-center">
                                            <v-textarea :disabled="isLoading" outlined counter rows="3" maxlength="120" v-model="msg" placeholder="Type Something" @keyup.enter="send"></v-textarea>
                                            <v-btn icon class="ml-4 mt-4" @click="send" :disabled="isLoading">
                                                <v-icon color="primary">mdi-send</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>

                <!--<v-container class="fill-height grey lighten-4 mb-20">

<v-row>

<v-col>

<v-card flat>

<v-card-title

>AI Assistant

<v-spacer></v-spacer>

<v-icon @click="chatView = !chatView">mdi-close</v-icon>

</v-card-title>

<v-card-subtitle class="text-overline"

>Ask questions to analyse your data</v-card-subtitle

>

</v-card>

</v-col>

</v-row>

<v-row class="fill-height pb-20 mb-20" align="end">

<v-col>

<div

v-for="(item, index) in chat"

:key="index"

:class="[

'd-flex flex-row align-center my-2',

item.from == 'user' ? 'justify-end' : null,

]"

>

<span v-if="item.from == 'user'" class="blue--text mr-3">{{

item.msg

}}</span>

<v-avatar

:color="item.from == 'user' ? 'indigo' : 'red'"

size="36"

>

<span class="white--text">{{ item.from[0] }}</span>

</v-avatar>

<span v-if="item.from != 'user'" class="blue--text ml-3">{{

item.msg

}}</span>

</div>

</v-col>

</v-row>

</v-container>

<v-footer fixed>

<v-container class="ma-0 pa-0">

<v-row no-gutters>

<v-col>

<v-card-text class="text-caption ml-n3"

>Please note this feature is experimental, it uses AI

and therefore sometimes will make things

up.</v-card-text

>

<v-select outlined dense label="Datasets"></v-select>

<div class="d-flex flex-row align-center">

<v-textarea

outlined

counter

rows="3"

maxlength="120"

v-model="msg"

placeholder="Type Something"

@keyup.enter="send"

></v-textarea>

<v-btn icon class="ml-4 mt-4" @click="send"><v-icon color="primary">mdi-send</v-icon></v-btn>

</div>

</v-col>

</v-row>

</v-container>

</v-footer>-->
            </v-container>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
import {
    userStore
} from '@/stores/UserStore';
import SnackBar from '@/components/other/SnackBar.vue';
import datasetsApi from '@/services/datasets';
import chatApi from '@/services/chat';
import SearchBar from '@/components/SearchBar.vue';
import LogoLoader from '@/components/LogoLoader.vue';
//import HelpButton from '@/components/other/HelpButton.vue'
import {
    showSnackBar
} from '@/utils/eventBus/'


export default {
    setup() {
        const user = userStore();
        return {
            // you can return the whole store instance to use it in the template
            user,
        };
    },
    components: {
        // global snackbar, accessible from all child components
        SnackBar,
        SearchBar,
        LogoLoader
        //HelpButton
    },
    data: () => ({
        drawer: null,
        chatView: false,
        breadcrumbs: [],
        chat: [],
        msg: null,
        isLoading: false,
        datasets: [],
        selectedDatasets: [],
        promptType: false,
        notifications: [{
                title: 'Upload Complete for dataset x, click to explore',
                type: 'Integrate',
            },
            {
                title: 'Model x training complete, 95% accuracy',
                type: 'Predict',
            },
            {
                title: 'User X has share a dashboard with you, click to view',
                type: 'Visualise',
            },
            {
                title: 'Your card expires soon, please update your details',
                type: 'Billing',
            },
        ],

        menu: [
            [1, 'Settings', '/settings'],
            [2, 'Admin', '/admin'],
            [3, 'Logout', '/logout'],
        ],
        items: [
            ['fa-thin fa-house', 'Home', '/', 'Account Dashboard'],
            //['fa-thin fa-bullseye-arrow', 'Strategies', '/playbooks', 'Playbooks'],
            ['fa-thin fa-link', 'Connections', '/connections', 'Upload Data'],
            ['fa-thin fa-folders', 'Datasets', '/datasets', 'Explore Your Data'],
            [
                'fa-thin fa-sitemap',
                'Pipelines',
                '/pipelines',
                'Transform, Clean & Enrich Data',
            ],
            [
                'fa-thin fa-chart-mixed',
                'Analytics',
                '/analytics',
                'View Your Saved Analytics',
            ],
            ['fa-thin fa-microchip-ai', 'AI Insights', '/insights', 'Insights'],
            [
                'fa-thin fa-brain-circuit',
                'AI Hub',
                '/ai-hub',
                'Review Your AI Models',
            ],
            [
                'fa-thin fa-comments-question-check',
                'ChatIVP',
                '/chat',
                'Ask Questions To Explore Your Data',
            ],
            [
                'fa-thin fa-thin fa-cloud-question',
                'Help Center',
                '/help-centre',
                'Search our extensive help center for documentation and walkthrough videos.',
            ],
        ],
        viewOnlyItems: [
            ['fa-thin fa-house', 'Home', '/', 'Account Dashboard'],
            ['fa-thin fa-folders', 'Datasets', '/datasets', 'Explore Your Data'],
            [
                'fa-thin fa-chart-mixed',
                'Analytics',
                '/analytics',
                'View Your Saved Analytics',
            ],
            [
                'fa-thin fa-comments-question-check',
                'ChatIVP',
                '/chat',
                'Ask Questions To Explore Your Data',
            ],
            ['fa-thin fa-microchip-ai', 'AI Insights', '/insights', 'Insights'],
            [
                'fa-thin fa-thin fa-cloud-question',
                'Help Center',
                '/help-centre',
                'Search our extensive help center for documentation and walkthrough videos.',
            ],
        ],

        key: true,
        miniDrawer: false,
    }),
    async created() {
        if (this.user.getUserId) {
            this.getDatasets();

        }
    },
    async mounted() {
        if (this.user.getWhiteLabel) {
            this.$vuetify.theme.themes.light.primary = this.user.getWhiteLabelPrimary;
            console.log(this.user.getWhiteLabelColours)
            console.log(window.Apex)
            // eslint-disable-next-line
            window.Apex.colors = this.user.getWhiteLabelColours;
            // eslint-disable-next-line
            //window.Apex.fill.colors = this.user.getWhiteLabelColours;
            // eslint-disable-next-line
            //window.Apex.markers.colors = this.user.getWhiteLabelColours;
			console.log(window.Apex)
			
			await this.loadFont(this.user.getWhiteLabelFont, 'Custom Font')
			document.documentElement.style.setProperty('--font', 'Custom Font');
			// eslint-disable-next-line
			Apex.chart.fontFamily =  'Custom Font'
		} else {
			this.loadFont('https://discovr-analytics-data.fra1.digitaloceanspaces.com/Platform-Logos/Quicksand-VariableFont_wght.ttf', 'Base Font')
		}
    },
    computed: {
        computedItems() {
            if (this.user.getSubscription === 2) {
                const notAllowed2 = [];
                return this.items.filter(item => !notAllowed2.includes(item[1]))
            }
            if (this.user.getSubscription === 3) {
                const notAllowed3 = ['AI Insights'];
                return this.items.filter(item => !notAllowed3.includes(item[1]))
            } else {
                return this.items
            }
        },
        ignorePaths() {
            if (
                this.$route.name != 'Sign Up' &&
                this.$route.name != 'Login' &&
                this.$route.name != 'Password Reset' &&
                this.$route.query.embed != 'true'
            ) {
                return true;
            } else {
                return false;
            }
        },
        pageTitle() {
            return this.$route.meta.pageTitle;
        },
        subTitle() {
            return this.$route.meta.subTitle;
        },
        workspace() {
            return this.user.getCurrentWorkspace;
        },
        workspaces() {
            // if (this.user.workspaces.length === 0) {
            //     return []
            // } else {
            return this.user.getWorkspaces;
            // }
        },
    },
    watch: {
        $route(to, from) {
			if(from.name === 'Login' && this.$vuetify.theme.themes.light.options.fontFamily !== 'Custom Font' && this.user.getWhiteLabel){
				document.documentElement.style.setProperty('--font', 'Custom Font');
				location.reload()
			}
            if (this.breadcrumbs.length > 0) {
                this.breadcrumbs[this.breadcrumbs.length - 1].disabled = false;
            }
            this.breadcrumbs.push({
                text: to.meta.pageTitle,
                disabled: true,
                href: to.fullPath,
            });
        },
        chat() {
            this.$nextTick(() => {
                var element = this.$el.querySelector('#box');
                element.scrollTop = element.scrollHeight;
            });
        },
        workspace() {
            this.$nextTick(() => {
                this.getDatasets();
            });
        },
    },
    methods: {
        loadFont(fontUrl, fontName) {
			const fontFace = new FontFace(fontName, `url(${fontUrl})`);
      
			// Load the font asynchronously
			const a = fontFace.load().then(() => {
				document.fonts.add(fontFace);
				// Update Vuetify's theme with the loaded font
				console.log(this.$vuetify.theme.themes.light.options.fontFamily)
				this.$vuetify.theme.themes.light.options.fontFamily = fontName;
				console.log(this.$vuetify.theme.themes.light.options.fontFamily)
				return 'done'
			}).catch((error) => {
				console.error('Font loading error:', error);
				return 'done'
			});
			return a 
		},
        openSupport() {
            //eslint-disable-next-line
            Beacon('identify', {
                name: this.user.getName,
                email: this.user.getEmail,
                company: this.user.getOrg
            })
            //eslint-disable-next-line
            Beacon('open')
        },
        changeWorkspace(item) {
            this.user.setWorkspace(item);
            this.key = !this.key;
        },

        getWorkspaces() {
            this.user.setWorkspaces(this.user.getUserId);
        },

        goBack() {
            // line below fixes issue where focus remains on the back button
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
            this.$router.back();
        },
        navTo(link) {
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
            this.breadcrumbs = [];
            this.$router.push(link);
        },
        async timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        send() {
            if (!this.msg | (this.selectedDatasets.length === 0)) {
                this.chat.push({
                    role: 'assistant',
                    content: 'Please select a dataset and type a question',
                    actions: [],
                    id: null,
                });
            } else {
                this.isLoading = true;
                let localMsg = this.msg;
                this.msg = null;
                this.chat.push({
                    role: 'user',
                    content: localMsg,
                    actions: [],
                    id: null,
                });

                const type = !this.promptType ? 'sql' : 'chart';
                chatApi
                    .chat(this.user.getUserId, localMsg, this.selectedDatasets, type, this.user.getCurrentWorkspace, this.user.getCurrentChatId)
                    .then(async (res) => {
                        if (res.data.javascriptCode !== null) {

                            this.chat.push({
                                role: 'assistant',
                                content: 'Chart',
                                actions: [],
                                id: res.data.logId,
                            });
                            this.user.setChatId(res.data.chatId)
                            await this.timeout(300)
                            eval(res.data.javascriptCode);
                            this.isLoading = false;
                        } else {
                            this.msg = null;
                            this.chat.push({
                                role: 'assistant',
                                content: res.data.result,
                                actions: [],
                                id: res.data.logId,
                            });
                            this.user.setChatId(res.data.chatId)
                            this.isLoading = false;
                        }
                    })
                    .catch(() => {
                        this.chat.push({
                            role: 'assistant',
                            content: 'Sorry, I did not understand that, please add more information to your question',
                            actions: [],
                        });
                        this.isLoading = false;
                    });
            }
        },
        getDatasets() {
            datasetsApi.getDatasets(this.user.getCurrentWorkspace).then((res) => {
                this.datasets = res.data;
                this.isLoading = false;
            });
        },
        async openChat() {
            this.chat = [];
            this.chatView = !this.chatView;
            if (this.user.getCurrentChatId) {
                try {
                    const {
                        data
                    } = await chatApi.getChat(this.user.getCurrentChatId);
                    const logs = data.logs
                    for (let i = 0; i < logs.length; i++) {
                        this.chat.push({
                            role: (logs[i].type === 'user') ? 'user' : 'assistant',
                            content: (logs[i].type === 'user') ? logs[i].complete_message : (logs[i].js_message === null) ? logs[i].complete_message : 'Chart',
                            actions: [],
                            id: logs[i].log_id,
                        });
                        if (logs[i].js_message !== null) {
                            const regex2 = /myChart/g;
                            const regex3 = /maintainAspectRatio: false,/g
                            const javascriptCodeFormatted = logs[i].js_message.replace(regex2, logs[i].log_id + '-chart-chat').replace(regex3, '');
                            await this.timeout(300)
                            try {
                                eval(javascriptCodeFormatted);
                            } catch (error) {
                                console.error(error)
                            }

                        }
                    }
                } catch (error) {
                    console.error(error)
                }
            }
        },
        rank(logId, rank) {
            chatApi.rank(logId, rank)
                .then(() => {
                    console.log('success')
                    showSnackBar('Updated rating', 'success')
                })
                .catch(() => {
                    console.log('error')
                    showSnackBar('Failed to update rating', 'error')
                });
        }
    },
};
</script>

<style>
:root {
  --font: 'Base Font'; /* Define a CSS variable */
}

.v-application {
	font-family: var(--font), sans-serif !important;
}

.v-application .text-overline {
	font-family: var(--font), sans-serif !important;
}

.v-application .text-h6 {
	font-family: var(--font), sans-serif !important;
}

.v-application .text-body-2 {
	font-family: var(--font), sans-serif !important;
}

.v-application .caption {
	font-family: var(--font), sans-serif !important;
}

.fixedBottom {
	position: fixed !important;
	bottom: 0 !important;
	padding-bottom: 10px;
	width: 100%;
}

.v-icon {
	transition: all ease 500ms;
}

.chevron-rotate {
	transform: rotate(180deg);
}

.my-list {
	overflow-y: auto;
}

.chart-container {
  height: 300px;
}
</style>
