<template>
<flowy-drag-handle :data-cy="`flowyNode${node.id}`">
    <v-hover v-slot="{ hover }">
        <v-badge :color="nodeStatus.badgeColor" :content="nodeStatus.badgeContent" overlap :value="nodeStatus.badgeShow">
            <template v-slot:badge>
                <span class="font-weight-bold" v-html="nodeStatus.badgeContent"></span>
            </template>
            <!--<v-progress-circular v-show="true" size="25" indeterminate color="primary" class="mr-13"></v-progress-circular>-->
            <v-card :elevation="hover ? 5 : 0" :class="{ 'on-hover': hover }" flat color="accent" min-width="350">
                <v-card-title>{{ title }}
                    <v-spacer></v-spacer>
                    <v-switch :data-cy="`nodeSwitch${node.demoNodeIndex}`" v-model="saved" v-show="node.id != 1" color="primary" class="mt-n1 mr-n2 mb-n3" @change="updateSaveStatus($event)" :true-value="true" :false-value="false" :disabled="node.data.status === 'unconfigured'"></v-switch>
                </v-card-title>
                <v-card-subtitle>{{(node.data.step != null)?node.data.step.tableTo:null}}</v-card-subtitle>
                <v-card-actions class="mt-n3">
                    <v-spacer></v-spacer>
                    <v-btn color="error" small outlined @click="remove()" v-show="node.id != 1">Remove</v-btn>

                    <v-btn data-cy="previewButton" color="primary" outlined small @click="previewDataset()">Preview</v-btn>
                    <v-btn data-cy="configureButton" color="primary" small @click="configure()">Configure</v-btn>

                </v-card-actions>

                <!-- <v-btn class="mb-n16 ml-16">
                        hello
                    </v-btn>-->

                <!--<v-card-subtitle v-html="description"></v-card-subtitle>-->
                <v-dialog v-model="dialog" width="70%">
                    <v-card width="100%" data-cy="thiscardrighthere">
                        <v-card-title>{{title}}
                            <v-spacer></v-spacer>
                            <v-icon @click="dialog=!dialog">fa-thin fa-xmark</v-icon>
                        </v-card-title>
                        <BaseDataset v-if="description === 'Base Dataset'" :node="node" />
                        <Transformation v-if="description === 'Transformation'" :node="node" />
                        <Enrichment v-if="description === 'Enrichment'" :node="node" />
                        <AiModel v-if="description === 'AI Model'" :node="node" />
                    </v-card>
                </v-dialog>

                <v-dialog v-model="preview" fullscreen>
                    <v-card tile>
                        <v-card-title>Preview
                            <v-spacer></v-spacer>
                            <v-icon @click="preview=!preview">fa-thin fa-xmark</v-icon>
                        </v-card-title>
                        <v-card-subtitle>{{title}}</v-card-subtitle>
                        <v-data-table :headers="previewHeaders" :items="previewData" class="pa-3"></v-data-table>
                    </v-card>
                </v-dialog>
            </v-card>
        </v-badge>
    </v-hover>
</flowy-drag-handle>
</template>

<script>
import EventBus from '@/utils/eventBus'
import pipelinesApi from '@/services/pipelines';
import {
    userStore
} from '@/stores/UserStore';

import BaseDataset from './BaseDataset.vue'
import Transformation from './transformations/Transformation.vue'
import Enrichment from './enrichments/Enrichment.vue'
import AiModel from './aiModels/AiModel.vue'
import {
    showSnackBar
} from '@/utils/eventBus/'

export default {
    name: 'DemoNode',

    components: {
        BaseDataset,
        Transformation,
        Enrichment,
        AiModel
    },

    props: {

        remove: {
            type: Function,
            default: () => 1
        },
        node: {
            type: Object,
            default: () => {
                return {}
            }
        },
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        datacy: {
            type: String,
            default: ''
        },

    },
    setup() {
        const user = userStore()
        return {
            // you can return the whole store instance to use it in the template
            user,
        }
    },
    data() {
        return {
            text: 'This is component A',
            dialog: false,
            badgeColor: '',
            badgeContent: '',
            badgeShow: false,
            tableTo: null,
            preview: false,
            previewData: [],
            previewHeaders: [],
        }
    },
    computed: {

        saved: {

            get() {
                return this.node.saveNode
            },

            set(newName) {
                return newName
            }

        },

        nodeStatus() {

            if (this.node.data.status === 'unconfigured') {
                return {
                    badgeColor: 'blue',
                    badgeContent: '&#x2573;',
                    badgeShow: false,
                }
            } else if (this.node.data.status === 'running') {
                return {
                    badgeColor: 'orange',
                    badgeContent: '&#x2234;',
                    badgeShow: true,
                }
            } else if (this.node.data.status === 'success') {
                return {
                    badgeColor: 'green',
                    badgeContent: '&#10003;',
                    badgeShow: true,
                }
            } else if (this.node.data.status === 'failed' || this.node.data.status === 'broken') {
                return {
                    badgeColor: 'red',
                    badgeContent: '!',
                    badgeShow: true,
                }
            } else {
                return {
                    badgeColor: 'blue',
                    badgeContent: '&#x2573;',
                    badgeShow: false,
                }
            }
        }

    },
    created() {

        EventBus.$emit('emit-setup', {
            node: this.node,
            step: this.node.data.step
        });

    },

    mounted() {

        const handler = this.nodeStatusProcessing; // this.closeDialog

        const changeHandler = function (event) {

            handler(event)

        }

        EventBus.$on('emit-data', changeHandler);

    },

    methods: {

        nodeStatusProcessing(event) {

            if (event.node.id === this.node.id) {
                this.tableTo = event.step.tableTo;
            }
            this.dialog = false;
        },

        configure() {
            this.dialog = true;
        },

        previewDataset() {
            this.dialog = false;

            pipelinesApi.nodePreview(this.user.getCurrentWorkspace, this.node.data.step.tableToDatabaseName) // TODO -- push tableToDatabaseName into the demoNode
                .then(res => {

                    if(res.data.length === 0){
                        showSnackBar('No data to preview', 'error')
                        return
                    }

                    this.previewData = [];
                    this.previewHeaders = [];
                    let keys = Object.keys(res.data[0])
                    for (var i = 0; i < keys.length; i++) {
                        if (!keys[i].startsWith("ivp_") && keys[i] != '_fivetran_synced' && keys[i] != '_fivetran_deleted') {
                            let title = keys[i].replace(/_/g, " ");
                            let obj = {
                                id: i,
                                text: title.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
                                align: 'start',
                                sortable: false,
                                value: keys[i],
                            }
                            this.previewHeaders.push(obj)
                        }
                    }
                    this.previewData = res.data;

                    if (window.Cypress) {
                        window.previewData = this.previewData;
                    }
                    
                    this.preview = true;
                })
                .catch(() => {
                    showSnackBar('There was an error loading your data, please check your configuration or contact support if the issue persists', 'error')
                })

        },

        updateSaveStatus(event) {

            EventBus.$emit('emit-save-status', this.node, event);

        },

        // this is triggered on the save of the node -- all of the data to run a node is here
        closeDialog(event) {

            if (event.node.id === this.node.id) {
                this.tableTo = event.step.tableTo;
            }

            this.dialog = false;

        }

    }
}
</script>
