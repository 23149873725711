'use strict'

import datasetsApi from '@/services/datasets'
import {
   userStore
} from '@/stores/UserStore';

const {
   v4: uuidv4
} = require('uuid');

const nodeCode = {

   methods: {

      async processParentData() {
         console.log('processParentData');
         console.log(userStore())
        
         this.data.node = this.node;

         // if the node already exists, load existing data.step
         if (this.node.parentId !== -1 && this.node.data.step !== undefined) {
            this.data.step = this.node.data.step

            // else create a new empty data.step
         } else {
            this.data.step = {

               workspaceId: userStore().getCurrentWorkspace,

               type: null,
               schemaFrom: null,
               tableFrom: null,
               tableFromDatabaseName: null,
               tableFromColumns: [],
               tableFromNumericalColumns: [],
               tableFromCategoricalColumns: [],
               tableFromDateColumns: [],
               saveNode: false,

               schemaTo: null,
               tableTo: '',
               tableToDatabaseName: uuidv4().replace(/-/g, '_').toLowerCase(),
               tableToColumns: [],

               schemaFrom1: null,
               tableFrom1: null,
               tableFrom1DatabaseName: null,
               tableFrom1Columns: null,
               tableFrom1JoinColumn: null,

               schemaFrom2: null,
               tableFrom2Complete: null,
               tableFrom2DatabaseName: null,
               tableFrom2Columns: [],
               tableFrom2JoinColumn: null,
               tableFrom2ColumnsKeep: [],
               tableFrom2ColumnsKeepRenamed: [],

               // Transformations
               frontendFormula: '',
               backendFormula: '',
               columnToAdd: '',
               newColumnName: '',
               newColumnDataType: null,
               numTags: 1,
               filters: [{
                  column: {
                     data_type_id: null
                  },
                  operator: null,
                  type: 'value',
                  value: null
               }],
               andOr: [],
               tags: [{
                  filters: [{
                     column: {
                        data_type_id: null
                     },
                     operator: null,
                     type: 'value',
                     value: null
                  }],
                  andOr: [],
                  taggedValue: 'Tag 1',
               }],
               aggBy: [],
               aggs: [],
               calcs: [],

               tableFrom1ColumnsAppend: [{
                  column_name: 'column 1',
                  pretty_name: 'column 1',
                  'data_type_id': 2
               }],
               tableFrom2ColumnsAppend: [{
                  column_name: 'column 1',
                  pretty_name: 'column 1',
                  'data_type_id': 2
               }],

               // AI Models 
               targetColumn: null,
               outputColumn: null,
               categoricalColumns: [],
               continuousColumns: [],
               descriptiveColumns: [],

               // forecasting
               aggregationMethod: null,
               temporalResolution: 'Week',
               dateColumn: null,
               numPredictions: 26,

               naOrZero: '0',
               minDataPointsPerCategory: 10,
               trainStartDate: null,

               // pca 
               nComponents: 2
            };
         }



         await this.refreshCols()

         return 'complete'

      },

      onChange(location, fieldName) {

         console.log('onChange', this.node);
         console.log(location, fieldName);
         //this.errors = this.testForErrors(location, this.data.rules, fieldName, this.errors)

      },

      getColumns() {

         datasetsApi.getMapping(this.data.step.tableFrom2Complete.dataset_id)
            .then(res => {
               this.data.step.tableFrom2Columns = res.data;
            })
            .catch(err => {
               console.error(err)
            })

      },

      refreshCols() {
         try {
            
            this.data.step.schemaFrom1 = this.node.parentData.schemaTo;
            this.data.step.tableFrom1 = this.node.parentData.tableTo;
            this.data.step.tableFrom1DatabaseName = this.node.parentData.tableToDatabaseName;
            this.data.step.tableFrom1Columns = JSON.parse(JSON.stringify(this.node.parentData.tableToColumns));
            this.data.step.tableFrom1NumericalColumns = this.node.parentData.tableToColumns.filter(item => item.data_type_id === 1)
            this.data.step.tableFrom1CategoricalColumns = this.node.parentData.tableToColumns.filter(item => item.data_type_id === 2)
            this.data.step.tableFrom1DateColumns = this.node.parentData.tableToColumns.filter(item => item.data_type_id === 3)
            
            this.data.step.schemaFrom = this.node.parentData.schemaTo;
            this.data.step.tableFrom = this.node.parentData.tableTo;
            this.data.step.tableFromDatabaseName = this.node.parentData.tableToDatabaseName;
            console.log('processing', this.node.parentData.tableToColumns.length)
            const parentNode = userStore().getCurrentPipelineNodes.find(x => x.id === this.node.parentId);
            this.data.step.tableFromColumns = JSON.parse(JSON.stringify(parentNode.data.step.tableToColumns));
            this.data.step.tableFrom1Columns = JSON.parse(JSON.stringify(parentNode.data.step.tableToColumns));
            this.data.step.tableFromNumericalColumns = parentNode.data.step.tableToColumns.filter(item => item.data_type_id === 1)
            this.data.step.tableFromCategoricalColumns = parentNode.data.step.tableToColumns.filter(item => item.data_type_id === 2)
            this.data.step.tableFromDateColumns = parentNode.data.step.tableToColumns.filter(item => item.data_type_id === 3)
            return 'complete'
         } catch (error) {

            
            console.log(error)
            return 'complete'
         }   
         
      }

   }

}

export default nodeCode